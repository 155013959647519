<template>
  <div>
    <cti-list></cti-list>
    <cti-form-request :title="title"></cti-form-request>
  </div>
</template>

<script>
import CtiList from '@/components/services/cti/CtiList.vue';
import CtiFormRequest from '@/components/services/cti/CtiFormRequest.vue';
import { mapMutations } from 'vuex';

export default {
  data() {
    return {
      isTitle: false,
      isFooter: false,
      title: 'Подключить интеграцию'
    };
  },

  components: {
    CtiList,
    CtiFormRequest
  },

  created() {
    this.SHOW_LOADING(false);
  },

  methods: {
    ...mapMutations({ SHOW_LOADING: 'SHOW_LOADING' })
  }
};
</script>

<style></style>
