// eslint-disable-next-line camelcase
import logo_1c from '../assets/img/1c-direct.png';
import logoBitrix from '../assets/img/bitrix24.png';
import logoAmoCrm from '../assets/img/amocrm.png';
import logoYClients from '../assets/img/yclients.png';
import logoMegaplan from '../assets/img/logo-green.svg';
import logoClientiks from '../assets/img/clientiks.png';
import logoAlfa from '../assets/img/logo-alfa.png';
import logoIdent from '../assets/img/ident.png';
import logoIiko from '../assets/img/iiko-logo.png';

const BITRIX = 'Интеграция Битрикс24 и Виртуальной АТС – это набор инструментов, которые помогают расширить возможности бизнеса';
const AMOCRM = 'Синхронизируйте amoCRM и телефонию — данные по звонкам, сделкам и клиентам';
const YCLIENTS = 'Онлайн-запись и автоматизация: телефонии, клиентской базы, уведомлений и многого другого';
const MEGAPLAN = 'История взаимодействий: Звонки, письма, заказы и комментарии. Уведомления о просроченных делах и многое другое';
const CLIENTIKS = 'Облачная CRM система, для бизнеса, где взаимодействие с клиентом начинается с записи на время оказания услуги';
const WATERAPP = 'Программа для автоматизации доставки воды';
const ALFACRM = 'Программа для бизнеса, связанного с обучением. Интеграция с Виртуальной АТС позволит не забывать ни об одном ученике';
const CRM_1C = 'Звоните и принимайте звонки напрямую в 1С. Сохранение подробной информации по звонкам';
const IDENT = 'Интеграция c популярной программой управления стоматологической клиникой';
const Healtis = 'Crm для медицинских учреждений';
const IIKO = 'Crm для бизнеса HoReCa. Независимо от формы: кафе, рестораны, столовые на предприятиях, закусочные и т.д.';
const OTHER = 'Если в списке нет Вашей CRM - укажите её название при составлении заявки';

// Для карточек с кратким описанием
// eslint-disable-next-line import/prefer-default-export
export const crmCards = [
  {
    name: 'Битрикс24',
    engName: 'BITRIX',
    description: BITRIX,
    img: logoBitrix
  },
  {
    name: 'AmoCRM',
    engName: 'AMOCRM',
    description: AMOCRM,
    img: logoAmoCrm
  },
  {
    name: 'YClients',
    engName: 'YCLIENTS',
    description: YCLIENTS,
    img: logoYClients
  },
  {
    name: 'Megaplan',
    engName: 'MEGAPLAN',
    description: MEGAPLAN,
    img: logoMegaplan
  },
  {
    name: 'КлиентиксCRM',
    engName: 'CLIENTIKS',
    description: CLIENTIKS,
    img: logoClientiks
  },
  {
    name: 'AlfaCrm',
    engName: 'ALFACRM',
    description: ALFACRM,
    img: logoAlfa
  },
  {
    name: '1С CRM',
    engName: 'CRM_1C',
    description: CRM_1C,
    img: logo_1c
  },
  {
    name: 'IDENT',
    engName: 'IDENT',
    description: IDENT,
    img: logoIdent
  },
  {
    name: 'Healtis',
    engName: 'Healtis',
    description: Healtis,
    img: ''
  },
  {
    name: 'WaterApp',
    engName: 'WATERAPP',
    description: WATERAPP,
    img: ''
  },
  {
    name: 'iiko',
    engName: '',
    description: IIKO,
    img: logoIiko
  },
  {
    name: 'Другая CRM',
    engName: '',
    description: OTHER,
    img: ''
  }
];
