<template>
  <ServicesDescription :isPageWrapper="true" :isFooter="isFooter">
    <template #title>
      Подключить интеграцию
    </template>
    <template #body>
      <b-row>
        <b-col v-for="item in crmCards" :key="item.name" sm="6" xl="3" :class="$style.cardCrm">
          <b-card>
            <b-card-text :class="$style.cardBodyWrap" class="dflex space-between-v">
              <div>
                <div :class="$style.logoWrap">
                  <img v-if="item.img" :src="item.img" :alt="item.name" :class="$style.logo" />
                  <h4 v-else>{{ item.name }}</h4>
                </div>
                <div>
                  {{ item.description }}
                </div>
              </div>
              <router-link
              v-if="item.engName !== ''"
                :to="{
                  path: '/cri-description',
                  query: { cri: item.engName },
                }"
                >Подробнее</router-link
              >
            </b-card-text>
          </b-card>
        </b-col>
      </b-row>
    </template>
  </ServicesDescription>
</template>

<script>
import ServicesDescription from '@/components/shared/ServicesDescription.vue';
import { crmCards } from '@/api/crmCards';

export default {
  data() {
    return {
      isFooter: false,
      crmCards
    };
  },

  components: {
    ServicesDescription
  }
};
</script>

<style lang="scss" module>
.cardCrm {
  margin-bottom: 1rem;
}

.cardCrm:nth-last-child(-n + 1) {
  margin-bottom: 0;
}

.cardBodyWrap {
  height: 100%;
}

.logoWrap {
  height: 44px;
  padding-bottom: 0.5rem;
}

.logo {
  max-width: 100%;
  max-height: 100%;
}

@media (min-width: 1200px) {
  .cardCrm:nth-last-child(-n + 4) {
    margin-bottom: 0;
  }
}
</style>
